<template>
  <div class="overall">
    <div class="content">
      <el-form ref="form" :model="form">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form label-width="90px">
            <el-form-item label="审核状态">
              <el-select v-model.trim="form.status" placeholder="请选择">
                <el-option v-for="item in option" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" @click="seach()">查询</el-button>
            <el-button type="primary" @click="reset()">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
      <!-- <div>
        <el-button type="primary" @click="handleApproved()">审核通过</el-button>
        <el-button type="danger" @click="handleApprovalRejection()">驳回审核</el-button>
      </div> -->
        <!-- table -->
        <div class="tableBox">
          <el-table
              :data="tableData"
              v-loading="tableLoading"
              max-height="650"
              border
              ref="multipleTable"
              @selection-change="handleSelectionChange"
              style="width: 100%">
              <el-table-column
              type="selection"
              width="55">
              </el-table-column>
              <el-table-column
                  prop="createAt" 
                  label="申请时间"
                  width="200">
              </el-table-column>
              <el-table-column
                  prop="sitaNeme" 
                  label="门店">
              </el-table-column>
              <el-table-column
                  prop="phone" 
                  label="手机号">
              </el-table-column>
              <el-table-column
                  prop="leaveReson" 
                  label="离职原因">
              </el-table-column>
              <el-table-column
                  prop="leaveDate" 
                  label="离职时间">
              </el-table-column>
              <el-table-column
                  prop="status" 
                  label="审核状态">
              <template slot-scope="scope">
                {{scope.row.status === 1 ? '待审核' : '审核通过'}}
              </template>
              </el-table-column>
              <el-table-column
                    label="操作"
                    width="200">
                    <template slot-scope="scope">
                        <el-button
                        @click.native.prevent="handlePhoto(scope.$index, scope.row)"
                        type="primary">
                        车辆照片
                        </el-button>
                    </template>
                </el-table-column>
          </el-table>
      </div>
  <!-- 分页 -->
      <div class="paginationBox">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          background
          :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 车辆照片弹窗 -->
    <el-dialog
    title="车辆照片"
    :visible.sync="photoVisible"
    :before-close="beforeClose"
    width="30%">
      <el-row :gutter="20">
          <el-col :span="6" v-for="url in urlList" :key="url">
            <div class="imageBox" style="width: 150px; height: 150px; display: inline-block;">
                  <img :src="url" style="width: 100%; height: 100%" />
                  <div class="maskBox">
                    <i
                      class="el-icon-zoom-in"
                      style="cursor: pointer;"
                      @click="imgVisible = true; dialogImageUrl = url"></i>
                  </div>
                </div>
          </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="beforeClose()">返回</el-button>
        </span>
    </el-dialog>
<!-- 预览图弹窗 -->
    <el-dialog :visible.sync="imgVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
import {getList,} from '../../../api/quit';
import store from '../../../store';
export default {
  data(){
    return{
    option:[
          {value:1,label:'待审核'},
          {value:2,label:'审核通过'}
      ],
    form:{
    status:""
    },
    id:"",
    phone:"",
    leaveReson:"",
    leaveDate:"",
    vehicleImage:"",
    createAt:"",
    status:"",
    sitaNeme:"",
    ids:[],
    currentPage:1,
    pageSize:10,
    total:0,
    tableData: [],
    tableLoading: true,
    //车辆照片弹窗
    photoVisible:false,
    urlList:[],
    //预览图装填 
    dialogImageUrl: '',
    imgVisible: false,
    }
  },
mounted() {
  //下拉默认是待审核
  this.form.status=this.option[0].value;
  this.QuitList();
},
methods:{
  //列表
  async QuitList(){
      try {
        const params = {
         page:this.currentPage,
         limit:this.pageSize,
        }
        let values = {
          ...params,
          ...this.form,
          areaId:store.state.login.userinfo.id
        };
        
       this.tableLoading = true;
       let data =  await getList(values);
       this.total = data.total;
       this.tableData = data.leavejobList;
      } catch (error) {
        console.log("error",error)
      }
      this.tableLoading = false;
    },

  //搜索
  seach() {
    this.QuitList();
  },
  // 重置
  reset() {
      //下拉默认是待审核
    this.form.status=this.option[0].value;
    this.QuitList();
  },
  handlePhoto(index,rows){
    this.photoVisible=true;
    this.urlList = rows.vehicleImage.split(",");
    //console.log(this.urlList)
  },
  //新增/编辑页弹出框取消
  beforeClose() {
      this.photoVisible = false;
        },
  //复选框
  handleSelectionChange(selectionList) {
            this.ids = [];
            selectionList.forEach(ele => {
            this.ids.push(ele.id);
        });
        return this.ids.join(",");
      },
  //更改一页的条数
  handleSizeChange(size) {
        this.pageSize = size;
        //重新走页面初始化方法
        this.QuitList();
    },
  //更改页数
  handleCurrentChange(current) {
      this.currentPage = current;
      //重新走页面初始化方法
      this.QuitList();
  },
}
}
</script>

<style lang="scss" scoped>
.overall {
    .content{
        .tableBox {
            padding-top: 20px;
        }
        .paginationBox {
            padding-top: 20px;
            text-align: center;
        }
    }
}
</style>